import React from 'react'
import App from '../app/app'
import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO />
    <App />
  </>
)

export default IndexPage
